<template>
  <div class="page-account">
    <div class="page-account-header"></div>
    <div class="page-account-container">
      <SocialSign></SocialSign>
      <div class="xz-login">
        <el-form :model="loginForm" :rules="rules" ref="loginForm" autocomplete="on" label-position="left">
          <el-form-item prop="username" required>
            <el-input placeholder="请输入用户名" prefix-icon="el-icon-user" v-model="loginForm.username"></el-input>
          </el-form-item>
          <el-form-item prop="password" required>
            <el-input
              placeholder="请输入密码"
              prefix-icon="el-icon-lock"
              v-model="loginForm.password"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item class="page-account-auto-login">
            <el-checkbox v-model="loginForm.autologin">自动登录</el-checkbox>
            <router-link to="/forget">忘记密码</router-link>
          </el-form-item>
          <el-form-item>
            <el-button
              class="bigbtn"
              type="primary"
              :loading="btLoading"
              @click="onSubmit('loginForm')"
            >立即登录</el-button>
          </el-form-item>
        </el-form>
        <div class="page-account-other">

          <router-link to="/register" class="page-account-register">注册账户</router-link>
        </div>
      </div>
    </div>
    <PageFooter></PageFooter>
  </div>
</template>
<script>
import { getUserInfo } from '@/utils/auth';
import SocialSign from './components/social-sign.vue';
import PageFooter from './components/page-footer.vue';

export default {
  name: 'login',
  components: { SocialSign, PageFooter },
  data () {
    return {
      loginForm: {
        username: '',
        password: '',
        type: 'pc',
        autologin: false
      },
      btLoading: false,
      redirect: undefined,
      otherQuery: {},
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const { query } = route;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created () {
    const { query } = this.$route;
    console.log(query, 'query');
    console.log(window.location, 'quewindow.location.hrefry');
    if (getUserInfo() && JSON.parse(getUserInfo()).autologin) {
      this.loginForm = JSON.parse(getUserInfo());
      this.btLoading = true;
      const that = this;
      setTimeout(() => {
        that.onSubmit();
      }, 500);
      console.log(JSON.parse(getUserInfo()), 'getUserInfo()');
    }
  },
  methods: {
    onSubmit () {
      // const that = this;
      this.btLoading = true;
      // if (this.loginForm.autologin) {

      // }
      const { query } = this.$route;
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$store.dispatch('user/login', this.loginForm)
            .then(() => {
              console.log(query, 'query');
              if (query.redirect) {
                this.$router.push(query.redirect);
              } else {
                window.location.href = '/';
              }
              this.btLoading = false;
            })
            .catch(() => {
              this.btLoading = false;
            });
        } else {
          this.btLoading = false;
          return false;
        }
      });
    },
    getOtherQuery (query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    }
  }
};
</script>
<style lang="scss" scoped>
.page-account {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}
.page-account-header {
  text-align: right;
  position: fixed;
  top: 16px;
  right: 24px;
}
.page-account-container {
  flex: 1;
  padding: 32px 0;
  text-align: center;
  width: 384px;
  margin: 0 auto;
}
.xz-login {
  .page-account-auto-login {
    margin-bottom: 24px;
    text-align: left;
  }
  .page-account-auto-login a {
    float: right;
  }
  .page-account-other {
    margin: 24px 0;
    text-align: left;
    img {
      width: 24px;
      margin-left: 16px;
      cursor: pointer;
      vertical-align: middle;
      opacity: 0.7;
      transition: all 0.2s ease-in-out;
    }
    .page-account-register {
      float: right;
    }
  }
}
</style>
